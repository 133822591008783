<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>

    <v-card class="mt-4">
      <v-card-title class="text-h6 ml-3">Configuración general </v-card-title>
      <v-card-text class="ml-3">
        <span
          class="text-body-1"
          style="
    font-size: .875rem!important;
"
          >Solicitud predeterminada:
        </span>
        <v-select
          style="max-width: 24rem;"
          :items="[
            { value: 0, text: 'Preguntar para cada documento' },
            { value: 1, text: 'Siempre enviar a firmar' },
            { value: 2, text: 'Siempre firma presencial' }
          ]"
          @change="onDefaultSign"
          v-model="printerConfiguration.requestType"
          outlined
          dense
          required
        ></v-select>

        <div class="mb-3 text-align-left">
          <v-btn
            class="ml-3 mr-2"
            :loading="isEditing"
            color="primary"
            @click="editPrintSettings()"
          >
            Actualizar
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                small
                to="printer"
                :disabled="isEditing"
                class="fab-icon-custom mr-2 no-shadow-box"
              >
                <v-icon v-bind="attrs" v-on="on" size="28" color="#848484fc">
                  mdi-home-outline
                </v-icon>
              </v-btn>
            </template>
            <span>Volver</span>
          </v-tooltip>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "PrinterSettings",

  data: () => ({
    defaultSign: 0,
    defaultFlow: false,
    defaultDevice: false,
    isEditing: false,

    page: {
      title: "Ajustes impresora firmar.online"
    },
    breadcrumbs: [
      {
        text: "Ajustes impresora firmar.online",
        disabled: true
      }
    ]
  }),

  mounted() {},

  computed: {
    printerConfiguration() {
      return this.$store.state.printerConfiguration
        ? this.$store.state.printerConfiguration
        : {
            requestType: 0,
            useFlow: false
          };
    }
  },

  methods: {
    onDefaultSign(val) {
      this.defaultSign = val;
    },
    editPrintSettings() {
      this.isEditing = true;
      this.$store
        .dispatch("setPrinterConfiguration", {
          requestType: this.defaultSign,
          useFlow: false
        })
        .then(() => {
          this.$toasted.global.toast_success({
            message: "Se ha guardado correctamente la configuración"
          });
        })
        .catch(err => {
          console.log(err);
          this.$toasted.global.toast_error({
            message: "No se ha podido guardar la configuración"
          });
        })
        .finally(() => {
          this.isEditing = false;
        });
    },

    openFirmarOnlineSupport() {
      window.open("https://soporte.firmar.online", "_blank");
    }
  }
};
</script>
<style lang="scss"></style>
