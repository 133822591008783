var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":""}},[_c('BaseBreadcrumb',{attrs:{"title":_vm.page.title,"icon":_vm.page.icon,"breadcrumbs":_vm.breadcrumbs}}),_c('v-card',{staticClass:"mt-4"},[_c('v-card-title',{staticClass:"text-h6 ml-3"},[_vm._v("Configuración general ")]),_c('v-card-text',{staticClass:"ml-3"},[_c('span',{staticClass:"text-body-1",staticStyle:{"font-size":".875rem!important"}},[_vm._v("Solicitud predeterminada: ")]),_c('v-select',{staticStyle:{"max-width":"24rem"},attrs:{"items":[
            { value: 0, text: 'Preguntar para cada documento' },
            { value: 1, text: 'Siempre enviar a firmar' },
            { value: 2, text: 'Siempre firma presencial' }
          ],"outlined":"","dense":"","required":""},on:{"change":_vm.onDefaultSign},model:{value:(_vm.printerConfiguration.requestType),callback:function ($$v) {_vm.$set(_vm.printerConfiguration, "requestType", $$v)},expression:"printerConfiguration.requestType"}}),_c('div',{staticClass:"mb-3 text-align-left"},[_c('v-btn',{staticClass:"ml-3 mr-2",attrs:{"loading":_vm.isEditing,"color":"primary"},on:{"click":function($event){return _vm.editPrintSettings()}}},[_vm._v(" Actualizar ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"fab-icon-custom mr-2 no-shadow-box",attrs:{"fab":"","dark":"","small":"","to":"printer","disabled":_vm.isEditing}},[_c('v-icon',_vm._g(_vm._b({attrs:{"size":"28","color":"#848484fc"}},'v-icon',attrs,false),on),[_vm._v(" mdi-home-outline ")])],1)]}}])},[_c('span',[_vm._v("Volver")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }